<template>
  <div class="app_con">
    <div class="dateSelect">
      <el-radio-group v-model="rangeDate" size="small">
        <el-radio-button label="0">今天</el-radio-button>
        <el-radio-button label="1">昨天</el-radio-button>
        <el-radio-button label="2">7日内</el-radio-button>
        <el-radio-button label="3">30日内</el-radio-button>
      </el-radio-group>
      <div class="diyDate">
        <span class="commonLabel">自定义</span>
        <el-date-picker
          v-model="rangeDate"
          size="small"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

    </div>
    <div class="content m-t-20">
      <el-row :gutter="12">
        <el-col :span="6">
          <el-card shadow="always">
            <p>全部访客（人）</p>
            <h3>120.00</h3>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            <p>新增会员（人）</p>
            <h3>120.00</h3>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            <p>支付人数（人）</p>
            <h3>120.00</h3>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            <p>访问-支付转化率</p>
            <h3>5%</h3>
          </el-card>
        </el-col>
      </el-row>
      <div class="lineCon m-t-30">
        <div ref="chart" style="width: 100%; height: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import api from "@/api/common";

export default {
  data() {
    return {
      rangeDate: "",
    };
  },
  created() {},
  mounted() {
    this.getEchartData();
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          grid: {
            top: "10px",
            bottom: "20px",
            right: 0,
            left: "40px",
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: "line",
              smooth: true,
              lineStyle: { color: "#409EFF" },
              itemStyle: { color: "#409EFF" },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lineCon {
  width: 100%;
  height: 400px;
}
.diyDate {
  display: inline-block;
  margin-left: 20px;
}

.diyDate {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.content h3{ font-size: 28px;}
.content span{ margin-left: 20px; font-size: 15px;}
</style>
